exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-akcja-uwlaszczajaca-js": () => import("./../../../src/pages/akcja-uwlaszczajaca.js" /* webpackChunkName: "component---src-pages-akcja-uwlaszczajaca-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-deklaracja-czlonek-wspierajacy-js": () => import("./../../../src/pages/deklaracja-czlonek-wspierajacy.js" /* webpackChunkName: "component---src-pages-deklaracja-czlonek-wspierajacy-js" */),
  "component---src-pages-deklaracja-js": () => import("./../../../src/pages/deklaracja.js" /* webpackChunkName: "component---src-pages-deklaracja-js" */),
  "component---src-pages-deklaracja-przystapienie-js": () => import("./../../../src/pages/deklaracja-przystapienie.js" /* webpackChunkName: "component---src-pages-deklaracja-przystapienie-js" */),
  "component---src-pages-demokracja-bezposrednia-js": () => import("./../../../src/pages/demokracja-bezposrednia.js" /* webpackChunkName: "component---src-pages-demokracja-bezposrednia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-referendum-js": () => import("./../../../src/pages/referendum.js" /* webpackChunkName: "component---src-pages-referendum-js" */),
  "component---src-pages-statut-js": () => import("./../../../src/pages/statut.js" /* webpackChunkName: "component---src-pages-statut-js" */),
  "component---src-pages-zyciorys-js": () => import("./../../../src/pages/zyciorys.js" /* webpackChunkName: "component---src-pages-zyciorys-js" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

